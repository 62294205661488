<script setup>
const props = defineProps({
    pageCount: {
        type: Number,
        required: true
    },
    total: {
        type: Number,
        required: true
    },
    modelValue: {
    type: Number,
    default: 1 ,
    },
    size: {
        type: String,
        required: "lg",
    }
})

const pageCount = Math.ceil(props.total/props.pageCount)

const emit = defineEmits(['update:modelValue'])
  

// Logic for displaying pages with ellipsis if pageCount is greater than 5
const pages = computed(() => {
  const totalVisible = 5
  const halfVisible = Math.floor(totalVisible / 2)

  if (pageCount <= totalVisible) {
    return Array.from({ length: pageCount }, (_, i) => i + 1)
  }

  const visiblePages = []
  
  if (props.modelValue <= halfVisible + 1) {
    visiblePages.push(...[1, 2, 3, 4, 5, '...', pageCount])
  } else if (props.modelValue >= pageCount - halfVisible) {
    visiblePages.push(1, '...', ...Array.from({ length: totalVisible - 1 }, (_, i) => pageCount - 3 + i))
  } else {
    visiblePages.push(
      1,
      '...',
      props.modelValue - 1,
      props.modelValue,
      props.modelValue + 1,
      '...',
      pageCount
    )
  }
  
  return visiblePages
})


  
function changePage(page) {

if (page !== props.modelValue && page > 0 && page <= pages.value[pages.value.length-1]) {
    emit('update:modelValue', page)
}
}
</script>
<template>
    <nav class="flex justify-center items-center border shadow-lg rounded-lg m-4 bg-white transition">
      <button
        :disabled="modelValue === 1"
        @click="changePage(modelValue - 1)"
        :class="`py-2 px-2 w-full h-full flex justify-center items-center text-${size} hover:bg-gray-100 border-r-2 disabled:cursor-not-allowed transition`"
      >
        <i class="fa-solid fa-arrow-left"></i>
      </button>
  
      <button
        v-for="page in pages"
        :key="page"
        :class="`py-2 px-4 text-${size} border-r-2 hover:bg-gray-100 transition ${page === modelValue?'bg-indigo-500 text-white hover:bg-indigo-500':''}`"
        @click="changePage(page)"
      >
        {{ page }}
      </button>
  
      <button
        :disabled="modelValue === pages.length"
        @click="changePage(modelValue + 1)"
        :class="`py-2 px-2 w-full h-full flex justify-center items-center text-${size} hover:bg-gray-100  disabled:cursor-not-allowed transition`"
      >
        <i class="fa-solid fa-arrow-right"></i>
      </button>
    </nav>
</template>
  
  